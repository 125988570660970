.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 952px;

  margin: 0 auto;
  padding: 15px;
}

img {
  max-width: 100%;
  visibility: hidden;
}

.bg-image {
  background: url(./img/album-time-will-tell.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

.bg-image-2 {
  background: url(./img/press-photo-web.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  width: 50%;
  align-self: center;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 8px;
}

@media (min-width: 500px) {
  header {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1000px) {
  header {
    /* margin-top: -44px; */
  }
}

.title-container {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -15px; */
}

@media (min-width: 1000px) {
  .title-container {
    /* flex-direction: row;
    align-items: baseline;
    margin-bottom: 0px; */
  }
}

.top-title {
  /* text-align: center; */
}

.bottom-title {
  /* position: relative;
  left: -7rem;
  top: -20px;
  margin-right: -96px; */
  font-size: 6rem;
}

@media (min-width: 500px) {
  /* .bottom-title {
    text-align: unset;
    margin-right: -52px;
  } */
}

@media (min-width: 1000px) {
  /* .bottom-title {
    position: relative;
    left: -5rem;
    margin-right: -45px;
    top: 0px;
    top: 0px;
  } */
}

h1 {
  font-size: 4rem;
  font-family: "Helvetica-Textbook-LT-Roman";
}

h1 .scrolls {
  font-family: "Westbury-Signature-Demo-Version";
  font-size: 10.8rem;
  position: relative;
  left: 20px;
  top: 5px;
}

@media (min-width: 500px) {
  h1 {
    font-size: 7.4rem;
    position: relative;
    text-align: center;
  }
}

@media (min-width: 500px) {
  h1 .scrolls {
    font-family: "Westbury-Signature-Demo-Version";
    font-size: 18.8rem;
    position: relative;
    left: 39px;
    top: 5px;
  }
}

p,
li {
  font-size: 1rem;
  line-height: 1.875;
}

.art-credit {
  text-align: right;
  width: 25%;
  min-width: 105px;
  position: absolute;
  right: 0;
  bottom: -60px;
}

@media (min-width: 500px) {
  .art-credit {
    bottom: 130px;
  }
}

@media (min-width: 660px) {
  .art-credit {
    bottom: -10px;
  }
}

@media (min-width: 875px) {
  .art-credit {
    bottom: 102px;
  }
}

@media (min-width: 1205px) {
  .art-credit {
    position: absolute;
    right: -120px;
    width: initial;
    text-align: left;
    top: -8px;
  }
}

.cta {
  display: flex;
  justify-content: center;
}

@media (min-width: 1000px) {
  .cta {
    position: absolute;
    bottom: -8px;
  }
}

.cta a:first-of-type {
  margin-right: 1rem;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-div {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.cta-btn {
  font-size: 1.8rem;
  width: 90px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: white;
  padding: 8px 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.cta-btn:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.track-list {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

@media (min-width: 500px) {
  .track-list {
    margin-top: 22px;
  }
}

table {
  border-collapse: collapse;
  font-size: 1.3rem;
}

@media (min-width: 500px) {
  table {
    font-size: 1.8rem;
  }
}
@media (min-width: 800px) {
  table:first-of-type {
    margin-right: 28px;
  }
}

@media (min-width: 800px) {
  table {
    font-size: 2.6rem;
  }
}

td,
th {
  text-align: left;
  padding: 4px 12px 4px 4px;
}

tr td:first-of-type {
  width: 14px;
  text-align: right;
}

@media (min-width: 500px) {
  tr td:first-of-type {
    width: 28px;
  }
}

.youtube {
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  padding-top: 25px;
}

@media (min-width: 800px) {
  .youtube {
    margin-top: 30px;
  }
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-credit {
  align-self: flex-end;
}

/* .press-image {
  background: url(./img/MAX_PRESS-2-web-LQ.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  margin-top: 30px;
} */

@media (min-width: 800px) {
  .press-image {
    margin-top: 30px;
  }
}

.photo-credit {
  align-self: flex-start;
}

/* .logo-image {
  background: url(./img/numbers-logo-web-LQ.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  width: 115px;
  margin-top: 25px;
} */

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 67%;
  margin-bottom: 45px;
  margin-top: 30px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.buy-now {
  font-weight: bold;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  /* margin-top: 15px; */
}

.social-links li {
  padding: 2px 0.7rem;
}

.social-links li a {
  font-size: 1rem;
}

.social-links a:hover {
  color: lightblue;
}

.copyright {
  text-align: center;
  margin-top: 15px;
}
